import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject
export class MultiVoucherDataModel {
  @JsonProperty('id', Number) id: number = undefined;
  @JsonProperty('title', String) title: string = undefined;
  @JsonProperty('description', String) descriptionPrePlay: string = undefined;
  @JsonProperty('post', String) descriptionPostPlay: string = undefined;
  @JsonProperty('soldout', String) descriptionSoldout: string = undefined;
  @JsonProperty('inactive', String) descriptionInactive: string = undefined;
  @JsonProperty('redeem', String) redeemLabel: string = undefined;
  @JsonProperty('image', String) image: string = undefined;
  @JsonProperty('imageList', String) imageList: string = undefined;
  @JsonProperty('privacyFile', String) privacyFile: string = undefined;
  @JsonProperty('tecFile', String) tecFile: string = undefined;
  @JsonProperty('backgroundColor', String) backgroundColor: string = undefined;
  @JsonProperty('allowGuest', Boolean) allowGuest: boolean = undefined;
  @JsonProperty('allowMailNotificationFlow', Boolean) allowMailNotificationFlow: boolean = undefined;
  @JsonProperty('automaticallySendMailNotification', Boolean) automaticallySendMailNotification: boolean = undefined;
}
