import {CtaModel} from '../../../data/cta.model';
import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject
export class PostDetailModel {

  @JsonProperty('id', Number) id: number = undefined;
  @JsonProperty('title', String) title: string = undefined;
  @JsonProperty('content', String) content: string = undefined;
  @JsonProperty('style', String) style: string = undefined;
  @JsonProperty('image', String) image: string = undefined;
  @JsonProperty('ctaText', String) ctaText: string = undefined;
  @JsonProperty('cta', CtaModel) cta: CtaModel = undefined;

}
