import {JsonObject, JsonProperty} from 'json2typescript';
import {PostListItemModel} from './post-list-item.model';

@JsonObject
export class PostListModel {

  @JsonProperty('id', Number) id: number = undefined;
  @JsonProperty('title', String) title: string = undefined;
  @JsonProperty('content', String) content: string = undefined;
  @JsonProperty('style', String) style: string = undefined;
  @JsonProperty('image', String) image: string = undefined;
  @JsonProperty('items', [PostListItemModel]) items: PostListItemModel[] = undefined;

}
