import {JsonObject, JsonProperty} from 'json2typescript';
import {CtaModel} from '../../../data/cta.model';

@JsonObject
export class PostListItemModel {

  @JsonProperty('id', Number) id: number = undefined;
  @JsonProperty('title', String) title: string = undefined;
  @JsonProperty('style', String) style: string = undefined;
  @JsonProperty('image', String) image: string = undefined;
  @JsonProperty('idPostlist', Number) idPostlist: number = undefined;
  @JsonProperty('ctaText', String) ctaText: string = undefined;
  @JsonProperty('cta', CtaModel) cta: CtaModel = undefined;
  @JsonProperty('dateDisplayFrom', Number) dateFrom: number = undefined;
  @JsonProperty('dateDisplayTo', Number) dateTo: number = undefined;
  @JsonProperty('priority', Number) priority: number = undefined;
  @JsonProperty('domVisible', Boolean, true) visible = false;
  @JsonProperty('dateValidityFrom', Number, true) dateValidFrom: number = undefined;
  @JsonProperty('dateValidityTo', Number, true) dateValidTo: number = undefined;

  public isActive(): boolean {
    const time = new Date().getTime() / 1000;
    return time > this.dateFrom && time < this.dateTo;
  }

  public isValid(): boolean {
    const time = new Date().getTime() / 1000;
    return time > this.dateValidFrom && time < this.dateValidTo;
  }

}
