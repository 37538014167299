import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject
export class VoucherModel {
  @JsonProperty('codeVoucher', String) codeVoucher: string = undefined;
  @JsonProperty('dateAssignment', Number) dateAssignment: number = undefined;
  @JsonProperty('idUser', Number) idUser: number = undefined;
  @JsonProperty('idVoucher', Number) idVoucher: number = undefined;
  @JsonProperty('idVoucherType', Number) idVoucherType: number = undefined;
}
