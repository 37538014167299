import {JsonObject, JsonProperty} from 'json2typescript';
import {VoucherModel} from './voucher.model';

@JsonObject
export class VoucherCanPlayModel {

  @JsonProperty('canPlay', Boolean) canPlay: boolean = undefined;
  @JsonProperty('vouchers', [VoucherModel]) vouchers: VoucherModel[] = undefined;

}
